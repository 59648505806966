<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div>
    <div v-if="ftpFileData.connection">
      <b-row>
        <b-col cols="6" class="mt-50">
          <connection-register-input class="my-50" :connectionTypeID="4" v-model="ftpFileData.connection"/>
        </b-col>

        <b-col v-for="input in renderList" :key="input.label" :cols="input.cols" :md="input.cols" class="mt-1">
          <custom-input
            v-if="variables"
            id="custom-input"
            v-model="ftpFileData[input.label].register_1"
            :possibleValues="variables"
          >
            <template #label>
              <span class="text-capitalize">
                {{ input.label }} 
              </span>
            </template>
          </custom-input>
          <div v-else>
            <b-skeleton type="input" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</middleware-sidebar-outline>
</template>

<script>
import {
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BSkeleton,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../../VariablesPanel.vue";
import { makeToast } from "@/layouts/components/Popups";
import CustomInput from "../../CustomInput.vue";
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
import HelperTooltip from '@/layouts/components/HelperTooltip.vue';

import ConnectionRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/ConnectionRegisterInput.vue'

import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


  export default {
    mixins: [MiddlewaresMixin],
    components: {
    BPopover,
    BSidebar,
    BIcon,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BContainer,
    VuePerfectScrollbar,
    VariablesPanel,
    BSpinner,
    CustomInput,
    BSkeleton,
    HelperTooltip,
    ConnectionRegisterInput,
    MiddlewareSidebarOutline
  },
  props: {
    i18nKey: {
      type: String,
      required: true,
    },
    middlewareID: {
      type: Number,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    defaultAgentActionID: {
      type: Number,
      default: 3
    },
    defaultAgentBlockID: {
      type: Number,
      default: 1
    },
    renderList: {
      type: Array,
      required: true
    },
    ftpFileData: {
      type: Object,
      required: true
    },
    middlewareType: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uuidMap: {},
      variables: undefined,
      variant_map: undefined,
      agentList: [],

      isSaving: false,
    };
  },
  computed: {
    agents: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initializeAgents();
    },
    configureVars() {
      this.variant_map =
        this.$refs[this.getID("variables-panel")].getVariantMap();
      this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
    },
    isObject(item) {
      return item instanceof Object && !(item instanceof Array);
    },
    isArray(item) {
      return item instanceof Array;
    },
    buildAgents() {
      this.isSaving = true;

      Object.keys(this.ftpFileData).forEach((key) => {
        if (this.ftpFileData[key]){
          this.agentList.push(DefaultAgent.defineToAPI(this.ftpFileData[key], this.middlewareID));
        }
      })

      this.saveAgents();
    },
    saveAgents() {
      this.$store
        .dispatch("saveAgents", {
          agentList: this.agentList,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });

          this.$emit("saved", response.data);
        })
        .catch((error) => {
          this.isSaving = false;
        });
    },
    initializeAgents() {

      this.renderList.forEach((input) => {
        this.ftpFileData[input.label] = MiddlewareFunctions.getValueFrom(
          {
            default: DefaultAgent.setAgent(this, {
              id: null,
              enum_agent_action_id: this.defaultAgentActionID,
              enum_agent_block_id: this.defaultAgentBlockID,
              register_1: {
                source: "7",
              },
              register_2: {},
              register_destiny: {
                source: "16",
                value: input.source,
              },
              execution_order: 1,
              fatal_on_fail: true,
            }),
            source: "16",
            value: input.source,
            from: {
              value: "register_destiny",
              source: "register_destiny",
            },
            modifier: false,
            multiple: false,
            expected: {
              source: "7",
              from: "register_1",
            },
          },
          this.agents
        );
      })

      this.ftpFileData.connection = MiddlewareFunctions.getValueFrom(
          {
            default: DefaultAgent.setAgent(this, {
              id: null,
              enum_agent_action_id: this.defaultAgentActionID,
              enum_agent_block_id: this.defaultAgentBlockID,
              register_1: {
                source: "7",
              },
              register_2: {},
              register_destiny: {
                source: "16",
                value: 'CONNECTION',
              },
              execution_order: 1,
              fatal_on_fail: true,
            }),
            source: "16",
            value: 'CONNECTION',
            from: {
              value: "register_destiny",
              source: "register_destiny",
            },
            modifier: false,
            multiple: false,
            expected: {
              source: "7",
              from: "register_1",
            },
          },
          this.agents
        );

    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    sidebarShown() {
this.$emit("shown")
      this.isSaving = false;

      let el = document.getElementsByTagName("html");
      el[0].classList.add("hide-scrollbar");
    },
    sidebarHidden() {
      this.$emit("close");

      let el = document.getElementsByTagName("html");
      el[0].classList.remove("hide-scrollbar");
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>


<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}
</style>